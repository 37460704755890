$o-grid-mode: 'snappy';
$system-code: 'next-barrier-page';

// These imports are needed for n-messagging-client (Cookie banner)
@import 'n-ui-foundations/mixins';
@import '@financial-times/n-messaging-client/critical';
@import '@financial-times/n-messaging-client/main';

// This is needed for barriers on mobile/tablet devices to display correctly.
// The fix was made in next-product 4 years ago https://github.com/Financial-Times/next-product/pull/792
body {
	overflow-x: hidden;
}

// FIXME: Not sure why this has to be here. To investigate at a later date
.o-footer {
	margin-top: 0;
}